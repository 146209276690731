
body {
  background-color: #FFFFFF;
  zoom: 70%;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.1em;
}

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}